import styled from 'styled-components'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { useTheme } from '@itsrever/design-system'
import {
    RefundPaymentMethod,
    setRefundPaymentMethod,
    setKeepYourItem
} from '@/redux/return/returnSlice'
import BoxHeart from '@/assets/images/icons/BoxHeart'
import { useTranslate } from '@/hooks'
import { toRGB } from '@/utils'
import {
    GithubComItsreverReturnsApiPkgDomainRefundmethodsKeepYourItem as RestKeepYourItem,
    GithubComItsreverReturnsApiPkgDomainRefundmethodsRefundMethod as RestRefundMethod
} from '@itsrever/returns-api-types'
import { useTranslation } from 'react-i18next'
import { RefundTimings } from '@/redux/return/returnSlice'
import { formatStringPrice } from '@/utils'

interface RefundCardProps {
    method: RestRefundMethod
    keepYourItem?: RestKeepYourItem
    totalRefundPrice?: number
    displayShippingTag?: boolean
    keepYourItemOffered?: boolean
    selected?: boolean
}

export const RefundCard: React.FC<RefundCardProps> = ({
    method,
    keepYourItem,
    totalRefundPrice,
    selected = false
}) => {
    const { translate } = useTranslate()
    const { i18n } = useTranslation()
    const lang = i18n.language

    const dispatch = useAppDispatch()
    const theme = useTheme()

    const instantMethod =
        method.method === RefundPaymentMethod.GiftCard ||
        method.method === RefundPaymentMethod.PromoCode

    const tagBackgroundColor = instantMethod
        ? '#1b75eb'
        : selected
        ? theme.colors.primary.main
        : theme.colors.grey[0]
    const tagColor = theme.colors.common.white

    const currency =
        useAppSelector(
            (store) => store.returnsApi.order.response?.order?.money_format
        )?.currency ?? 'unknown'

    let totalAmount = (totalRefundPrice ?? 0) / 100
    if (keepYourItem?.enabled) {
        totalAmount = totalAmount * ((keepYourItem?.percentage ?? 1) / 100)
    }
    const returnTotalAmount = formatStringPrice(
        totalAmount.toString(),
        currency,
        lang
    )

    return (
        <div
            id={method.method?.toString() + '-kyi'}
            className="relative flex content-center items-center"
            onClick={() => {
                dispatch(setRefundPaymentMethod(method ?? 0))
                dispatch(setKeepYourItem(keepYourItem?.enabled ?? false))
            }}
        >
            <Card
                selected={selected}
                isBlueOption={true}
                borderColor={theme.colors.primary.main}
                bgColor={toRGB(theme.colors.primary.main)}
            >
                <div className="flex items-center justify-between gap-2">
                    <div className="flex items-center gap-2">
                        <div className="opacity-60">
                            <BoxHeart />
                        </div>
                        <p className="text-lg">
                            <b>{translate(`kyi_title`)}</b>
                        </p>
                    </div>
                    {method.timing === RefundTimings.OnStartReturnProcess ? (
                        <div>
                            <Tag
                                color={tagColor}
                                bgroundColor={tagBackgroundColor}
                                selected={selected}
                            >
                                <TagText
                                    dangerouslySetInnerHTML={{
                                        __html: translate(
                                            `refund_card_time_method${method.method}`
                                        )
                                    }}
                                />
                            </Tag>
                        </div>
                    ) : null}
                </div>

                <div className="flex justify-between md:gap-6 ">
                    <div
                        className={
                            'relative my-2 flex items-center gap-4 overflow-hidden rounded-md py-2 pr-3 ' +
                            (selected ? 'bg-blue-200' : 'bg-neutral-100')
                        }
                    >
                        <p className="z-[5] mx-2 flex shrink-0 text-lg font-bold text-blue-600">
                            <span>{returnTotalAmount}</span>
                        </p>
                        <p className="flex flex-col items-start font-normal">
                            <span
                                className={
                                    'mb-1 rounded px-2 text-xs ' +
                                    (selected
                                        ? 'bg-blue-300'
                                        : 'bg-neutral-300')
                                }
                            >
                                {translate(
                                    `refund_card_method${method.method}_title`
                                )}
                            </span>
                            <span
                                className="text-xs"
                                dangerouslySetInnerHTML={{
                                    __html: translate(`kyi_card_description`, {
                                        returnTotalAmount: returnTotalAmount
                                    })
                                }}
                            />
                        </p>
                        <div
                            className={
                                'absolute left-[-4.5em] h-36 w-36 rounded-full ' +
                                (selected ? 'bg-blue-300' : 'bg-blue-100')
                            }
                        ></div>
                    </div>
                </div>
            </Card>
        </div>
    )
}

export default RefundCard

interface TagProps {
    color: string
    bgroundColor: string
    selected: boolean
    isAnimating?: boolean
}

const Tag = styled.div<TagProps>`
    @keyframes slideInFromLeft {
        from {
            width: 88px;
        }
        to {
            width: 100px;
        }
    }
    @keyframes slideOutFromLeft {
        from {
            width: 100px;
        }
        to {
            width: 88px;
        }
    }

    width: 80px;
    align-self: center;
    margin-top: 8px;
    white-space: nowrap;
    background-color: ${(p) => p.bgroundColor};
    color: ${(p) => p.color};
    text-align: center;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    padding: 3px 0;
    margin-right: ${(p) => (p.selected ? '-10px' : '-12px')};
    transform-origin: right;
    animation: ${(p) =>
        p.selected
            ? `slideInFromLeft 0.2s ease-in-out forwards`
            : 'slideOutFromLeft 0.2s ease-in-out forwards'};
`

const TagText = styled.p`
    font-size: 0.6rem;
    line-height: 8px;
`

interface CardProps {
    selected: boolean
    isBlueOption: boolean
    borderColor: string
    bgColor: string
}

const Card = styled.div<CardProps>`
    padding: ${(p) => (p.selected ? '12px 10px' : '14px 12px')};
    cursor: pointer;
    border: ${(p) => (p.selected ? '2px' : '0px')} solid;
    border-radius: 0.25rem;
    width: 100%;
    margin-bottom: 1.5rem;
    border-color: ${(p) =>
        p.selected ? (p.isBlueOption ? '#1b75eb' : p.borderColor) : ''};
    background-color: ${(p) =>
        p.selected
            ? p.isBlueOption
                ? 'rgb(27, 117, 235, 0.1)'
                : p.bgColor
            : ''};
    box-shadow: ${(p) =>
        p.selected ? '' : '0px 0px 2px 2px rgb(0 0 0 / 0.1)'};
`
