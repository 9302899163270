import Summary from '@/components/Summary'
import { ReverCard } from '@/components/ReverCard'
import styled from 'styled-components'
import { RefundInformation } from '@/components/RefundInformation'
import { ExchangeInformation } from '@/components/ExchangeInformation'
import { useTranslation } from 'react-i18next'
import DOMPurify from 'dompurify'
import { useAppSelector } from '@/redux/hooks'
import GiftCardIcon from '@/assets/images/icons/GiftCardIcon'
import CopyIcon from '@/assets/images/icons/copy.svg'
import { useState, useContext } from 'react'
import { useTheme } from '@itsrever/design-system'
import { TrackingHeader } from '@/components/TrackingComponents'
import { TrackingContext } from '@/layout/TrackingLayout'
import { LineItemSubtypes } from '@/redux/return/returnSlice'
import copiesMap from './copies_info_map.json'

function ReturnDetails() {
    const { t, i18n } = useTranslation()
    const trackingInfo = useContext(TrackingContext)
    const theme = useTheme()

    const ecommerceName = useAppSelector(
        (store) => store.settingsApi.settings.response.name
    )

    const summary = trackingInfo?.summary
    const returnedItems = summary?.returned_items ?? []
    const exchangeItems = summary?.exchange_items ?? []
    const costs = summary?.costs ?? []
    const RDVSurplus = costs.find(
        (item) => item.subtype === LineItemSubtypes.RdvSurplus
    )

    const subTotal = () => {
        let subTotal = 0
        returnedItems.forEach((item) => {
            subTotal += Number(item.total ?? 0) ?? 0
        })
        exchangeItems.forEach((item) => {
            subTotal += Number(item.total ?? 0) ?? 0
        })
        return subTotal
    }
    const total = () => {
        let total = subTotal()
        costs.forEach((cost) => {
            total += Number(cost.total ?? 0) ?? 0
        })
        return total
    }

    const isExchange = exchangeItems.length > 0
    const newExchangeOrderNumber =
        trackingInfo?.exchange_order_info?.order_number ?? ''

    const amountFormatted =
        trackingInfo?.refund_info?.status === 'FULLY_REFUNDED'
            ? trackingInfo?.refund_info?.executed_formatted_amount ?? ''
            : trackingInfo?.refund_info?.pending_formatted_amount ?? ''

    const paymentMethod = trackingInfo?.refund_info?.payment_method
    const timing = trackingInfo?.refund_info?.timing
    const status = trackingInfo.status
    const refundStatusExecution =
        trackingInfo?.refund_info?.status === 'FULLY_REFUNDED' ||
        trackingInfo?.refund_info?.status === 'PARTIALLY_REFUNDED'
            ? 'EXECUTED'
            : 'NOT_EXECUTED'

    const isGiftCard =
        trackingInfo?.refund_info?.payment_method === 'GIFT_CARD' ||
        trackingInfo?.refund_info?.payment_method === 'PROMO_CODE'
    const giftCardCode = trackingInfo?.refund_info?.gift_card_code

    const refundDate = new Date(trackingInfo?.refund_info?.refunded_at ?? '')
    const refundedAt = refundDate.toLocaleDateString(i18n.language, {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    })

    const refundNotNecessary =
        trackingInfo?.refund_info?.status === 'REFUND_NOT_NECESSARY'

    const [codeCopied, setCodeCopied] = useState(false)
    const showCodeCopied = () => {
        setCodeCopied(true)
        setTimeout(() => {
            setCodeCopied(false)
        }, 2000)
    }

    type CopyGroupMap = { [key: string]: string }
    const typedCopiesMap: CopyGroupMap = copiesMap as CopyGroupMap

    const mainCopyKey = () => {
        const key = ('timing_' +
            timing +
            '_method_' +
            paymentMethod +
            '_status_' +
            status +
            '_refund_' +
            refundStatusExecution) as string
        return typedCopiesMap[key] ?? ''
    }

    return (
        <MainDiv bgcolor={theme.colors.grey[5]}>
            <TrackingHeader>
                <div className="flex w-full justify-center text-brand-900">
                    <div className="ml-7 w-[300px] md:ml-0">
                        <h4 className="text-2xl">
                            {t('tracking_pages.return_details')}
                        </h4>
                        <p className="mt-2">
                            {t('tracking_pages.description')}
                            {ecommerceName}
                        </p>
                    </div>
                </div>
            </TrackingHeader>

            <ContentDiv>
                <div className="flex flex-col items-center gap-8 text-slate-800">
                    {isExchange ? (
                        <ExchangeInformation
                            exchangedItems={exchangeItems}
                            newExchangeOrderNumber={newExchangeOrderNumber}
                        />
                    ) : null}
                    {!refundNotNecessary ? (
                        <RefundInformation
                            amountFormatted={amountFormatted}
                            paymentMethod={
                                trackingInfo?.refund_info?.payment_method
                            }
                            status={trackingInfo?.refund_info?.status}
                            refundedAt={refundedAt}
                        />
                    ) : null}
                </div>
                {status === 'AUTHORIZATION_PENDING' ? (
                    <p className="mt-4">
                        {t('tracking.pending_authorization', {
                            ecommerce_name: ecommerceName
                        })}
                    </p>
                ) : status === 'NOT_AUTHORIZED' ? (
                    <>
                        <p className="mt-4">
                            {t('tracking.not_authorized', {
                                ecommerce_name: ecommerceName
                            })}
                        </p>
                    </>
                ) : status === 'DOCUMENT_UPLOAD_PENDING' ? (
                    <>
                        <p className="mt-4">
                            {t('tracking_pages.documents_required')}
                        </p>
                    </>
                ) : (
                    <>
                        {!refundNotNecessary ? (
                            <p
                                className="mt-3"
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(
                                        t(`tracking.${mainCopyKey()}`)
                                    )
                                }}
                            />
                        ) : (
                            <p
                                className="mt-6"
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(
                                        t('tracking.ops_info_10')
                                    )
                                }}
                            />
                        )}
                    </>
                )}

                {isGiftCard && giftCardCode ? (
                    <div className="mt-2 flex w-64 items-center justify-center rounded-xl border border-solid border-brand-200 bg-brand-50 bg-gradient-to-b from-brand-50 to-brand-100 p-3 shadow-md">
                        <p className=" mr-6 scale-[2] text-brand-400">
                            <GiftCardIcon />
                        </p>

                        <div className="relative text-brand-900">
                            <p>{giftCardCode}</p>
                            <p className=" absolute left-0 top-0 w-full bg-brand-50 italic text-brand-900">
                                {codeCopied ? 'Code copied!' : null}
                            </p>
                        </div>
                        <div
                            className="ml-2 cursor-pointer rounded-lg  p-1 hover:bg-brand-200"
                            onClick={() => {
                                showCodeCopied()
                                navigator.clipboard.writeText(giftCardCode)
                            }}
                        >
                            <img
                                className="size-5 opacity-30"
                                src={CopyIcon}
                            ></img>
                        </div>
                    </div>
                ) : null}
                <Card>
                    <div className="p-5">
                        <Summary
                            returnedItems={returnedItems}
                            exchangeItems={exchangeItems}
                            costs={costs}
                            totalRefundAmount={total()}
                            RDVSurplus={RDVSurplus}
                        />
                    </div>
                </Card>
            </ContentDiv>
        </MainDiv>
    )
}

export default ReturnDetails

const Card = styled(ReverCard)`
    @media (max-width: 899px) {
        max-width: 20rem;
    }
`

const MainDiv = styled.div<{ bgcolor: string }>`
    flex-grow: 1;
`

const ContentDiv = styled.div`
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 1.5rem;
`
